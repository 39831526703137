import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { buildConfigurationValidationRuleCategoriesUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { ValidationRuleCategoriesResponse, Category } from '../types';
import { CATEGORY_TYPES_RESPONSE_KEY } from '../../configuration/types';

export const loadValidationRuleCategories = (
    actionName: string,
    processResultFunction: Function,
): Observable<{
    result: Category[];
    actionName: string;
    processResultFunction: Function;
}> => {
    const obs = sendRetrieveRequest(buildConfigurationValidationRuleCategoriesUrl());
    const responseKey = `${CATEGORY_TYPES_RESPONSE_KEY.VALIDATION_RULE}Category`;
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ValidationRuleCategoriesResponse>) => {
            const results = convertToCamel<Category[]>(response[responseKey]);
            return observableOf({ result: results, actionName, processResultFunction });
        }),
    );
};
